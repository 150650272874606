<template>
  <van-tab v-bind="$attrs">
    <div class="tab-content">
      <card :title="taskDetail.title">
        <div class="info-item space-between">
          <div class="label">任务发送人</div>
          <div class="value">{{ taskDetail.createBy }}</div>
        </div>
        <div class="info-item space-between">
          <div class="label">任务发布时间</div>
          <div class="value">{{ taskDetail.createTime }}</div>
        </div>
        <div class="info-item space-between">
          <div class="label">任务验货员</div>
          <div class="value">{{ taskDetail.yanhuoUserName }}</div>
        </div>
        <div class="info-item space-between">
          <div class="label">验货员电话</div>
          <div class="value highlight" @click="handleCall(taskDetail.yanhuoUserPhone)">
            <span>{{ taskDetail.yanhuoUserPhone }}</span>
            <img class="icon-phone" src="~@/assets/svg/icon-phone.svg" alt="">
          </div>
        </div>
      </card>
      <card title="验货流程">
        <div class="work-flow">
<!--          {{ taskDetail.yanhuoFollow }}-->
          <p>1、点数抽样</p>
          <p>2、条码扫描</p>
          <p>3、拍摄照片</p>
          <p>4、核对检验清单</p>
          <p>5、提交报告</p>
        </div>
        <divider/>
        <div class="available-date">
          <div class="title">可验货日期</div>
          <div class="date">
            <span>{{ taskDetail.startDate }}</span>
            <span> 至 </span>
            <span>{{ taskDetail.endDate }}</span>
          </div>
        </div>
      </card>
      <card title="工厂信息">
        <div class="info-item space-between">
          <div class="label">供应商</div>
          <div class="value">{{ taskDetail.gongyinshangUserName }}</div>
        </div>
        <div class="info-item space-between">
          <div class="label">验货工厂</div>
          <div class="value">{{ taskDetail.companyName }}</div>
        </div>
        <div class="info-item space-between">
          <div class="label">验货地址</div>
          <div class="value highlight" @click="handleAddrClick">
            {{ taskDetail.companyArea }}{{ taskDetail.companyDetailAdress }}
          </div>
        </div>
        <div class="info-item space-between">
          <div class="label">工厂联系人</div>
          <div class="value">{{ taskDetail.relationName }}</div>
        </div>
        <div class="info-item space-between">
          <div class="label">工厂联系方式</div>
          <div class="value highlight" @click="handleCall(taskDetail.relationTelephone)">
            <span>{{ taskDetail.relationTelephone }}</span>
            <img class="icon-phone" src="~@/assets/svg/icon-phone.svg" alt="">
          </div>
        </div>
      </card>
    </div>
  </van-tab>
</template>

<script>
import Card from '@/components/Common/Card.vue'
import Divider from "@/components/Common/Divider.vue";

export default {
  name: 'TaskInfoTab',
  components: {Divider, Card},
  props: {
    id: {
      type: String,
      required: true
    },
    taskDetail: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  methods: {
    handleCall(phone) {
      window.location.href = `tel:${phone}`
    },
    handleAddrClick() {
      if(this.taskDetail.lat && this.taskDetail.lng){
        this.Dialog.confirm({
          message: '是否跳转到微信地图？',
          confirmButtonColor: '#055C9D'
        }).then(()=>{
          wx.openLocation({
            latitude: parseFloat(this.taskDetail.lat), // 纬度，浮点数，范围为90 ~ -90
            longitude: parseFloat(this.taskDetail.lng), // 经度，浮点数，范围为180 ~ -180。
            name: '', // 位置名
            address: this.taskDetail.companyArea + this.taskDetail.companyDetailAdress, // 地址详情说明
            scale: 12, // 地图缩放级别,整型值,范围从1~28。默认为最大
            infoUrl: '' // 在查看位置界面底部显示的超链接,可点击跳转
          });
        }).catch(()=>{

        })
      }
    },
  }
}
</script>

<style scoped lang="less">
.tab-content {
  height: calc(100vh - 148px);
  .highlight {
    color: #055C9D;
  }

  .icon-phone {
    width: 12px;
    height: 12px;
    margin-left: 10px;
  }

  .info-item {
    line-height: 20px;
    margin-bottom: 8px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .label {
      width: 80px;
      font-size: 12px;
      color: rgba(#000, 0.4);
    }

    .value {
      width: calc(100% - 80px);
      font-size: 14px;
    }
  }

  .work-flow {
    font-size: 14px;
    p{
      margin: 0 0 8px;
    }
    padding-bottom: 8px;
  }

  .available-date {
    .title {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      padding: 12px 0;
    }
    .date{
      font-size: 14px;
      color: #2F3133;
    }
  }
}
</style>
